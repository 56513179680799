/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

const BrandSlider = () => {
    return (
        <>
            <div class="row mt-5 d-lg-block d-none">
                <div class="col-lg-6 mx-auto">
                    <div class="site-heading text-center">
                        <span class="site-title-tagline"><i class="flaticon-drive"></i> Popular Brands</span>
                        <h2 class="site-title">Common Car  <span>Makes</span></h2>
                        <div class="heading-divider"></div>
                    </div>
                </div>
            </div>
            <div className='container mb-4 d-lg-block d-none'>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="BrandSlider"
                >
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay=".25s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/01.png" alt="" />
                            </div> */}
                            <h5>Ferrari</h5>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay=".50s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/02.png" alt="" />
                            </div> */}
                            <h5>Hyundai</h5>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay=".75s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/03.png" alt="" />
                            </div> */}
                            <h5>Mercedes Benz</h5>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay="1s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/04.png" alt="" />
                            </div> */}
                            <h5>Toyota</h5>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay="1.25s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/05.png" alt="" />
                            </div> */}
                            <h5>BMW</h5>
                        </a>
                    </SwiperSlide>
                    <SwiperSlide>
                        <a href="#" class="brand-item wow fadeInUp" data-wow-delay="1.50s">
                            {/* <div class="brand-img">
                                <img src="assets/img/brand/06.png" alt="" />
                            </div> */}
                            <h5>Nissan</h5>
                        </a>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}

export default BrandSlider