

import { gql } from '@apollo/client';

export const BUYEREGISTER = gql`
mutation BuyerRegister($firstName: String!,$lastName: String!, $dob:String,
    $email:String!,$password:String!,$country:String,
    $phone:String,$profilePic: String,$username:String!){
      createBuyer(firstName:$firstName,lastName:$lastName,dob:$dob,email:$email,password:$password,country:$country,phone:$phone,profilePic:$profilePic,username:$username){
        id
        email
        firstName
        lastName
        dob
        phone
        profilePic
        country
        role
        username
      }
    }
`;

export const BUYERLOGIN = gql`
mutation BuyerLogin($email:String!,$password:String!){
    userLogin(email:$email,password:$password){
        token
        user{
            id
            email
            role
            firstName
            lastName
        }
      }
    }
`;