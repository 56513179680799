import React from "react";

const HomeFilter = () => {
  return (
    <>
      <div className="find-car my-4">
        <div className="container-fluid">
          <div className="find-car-form">
            <h1 className="text-center my-2 theme-color">Quick Search</h1>
            <form action="#">
              <div className="row my-4">
                <div className="col">
                  <div className="form-group">
                    <label className="text-center">Inventory</label>
                    <select className="nice-select select">
                      <option value="1">Inventory</option>
                      <option value="Copart Inventory">Copart Inventory</option>
                      <option value="IAAI Inventory">IAAI Inventory</option>
                      <option value="All Inventory">All Inventory</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="text-center">Year</label>
                    <select className="nice-select select">
                      <option value="1">Year</option>
                      <option value="2">Copart</option>
                      <option value="3">IAAI</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="text-center">Make</label>
                    <select className="nice-select select">
                      <option value="1">Make</option>
                      <option value="2">BMW</option>
                      <option value="6">Nissan</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label className="text-center">Model</label>
                    <select className="nice-select select">
                      <option value="1">All Model</option>
                      <option value="2">3-Series </option>
                      <option value="3">Carrera</option>
                      <option value="4">G-TR</option>
                      <option value="3">Macan</option>
                      <option value="3">N-Series</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Vehicle Type</label>
                    <select className="nice-select select">
                      <option value="All Vehicle Type">All Vehicle Type</option>
                      <option value="Vehicle">Vehicle</option>
                      <option value="Bike">Bike</option>
                      <option value="Truck">Truck</option>
                      <option value="ATVs">ATVs</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Body Type</label>
                    <select className="nice-select select">
                      <option value="All Vehicle Type">All Vehicle Type</option>
                      <option value="Vehicle">Vehicle</option>
                      <option value="Bike">Bike</option>
                      <option value="Truck">Truck</option>
                      <option value="ATVs">ATVs</option>
                    </select>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group">
                    <label>Sale Type</label>
                    <select className="nice-select select">
                      <option value="Buy Now">Buy Now</option>
                      <option value="others">others</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label>Salvage or clean/clear</label>
                    <select className="nice-select select">
                      <option value="" disabled selected>
                        Title
                      </option>
                      <option value="Clean/Clear">Clean/Clear</option>
                      <option value="Salvage">Salvage</option>
                      <option value="Bill of Sale">Bill of Sale</option>
                      <option value="All">All</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      className="form-control"
                      placeholder="State"
                      type="search"
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Zip</label>
                    <input
                      className="form-control"
                      placeholder="Zip"
                      type="search"
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      className="form-control"
                      placeholder="Country"
                      type="search"
                    />
                  </div>
                </div>
                <div className="col-lg-3 align-self-center pe-4 mt-4">
                  <button className="theme-btn ms-auto d-block" type="submit">
                    <span className="far fa-search"></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFilter;
