/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Footer = () => {
    return (
        <>

            <footer className="footer-area">
                <div className="footer-widget">
                    <div className="container">
                        <div className="row footer-widget-wrapper pt-5">
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box about-us">
                                    <h4 className="footer-widget-title">Nigeria</h4>
                                    <div class="header-top-contact">
                                        <ul>
                                            <li><a href="#"> Mon - Fri (09AM - 07PM) View All Office Locations</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box list">
                                    {/* <h4 className="footer-widget-title">Auto Auctions</h4> */}
                                    <div className="footer-widget-box about-us">
                                        <ul className="footer-contact">
                                            <li><a href="tel:+21236547898"><i className="far fa-phone"></i>Call us: +234 9015362533</a></li>
                                            <li><a href="tel:+21236547898"><i className="far fa-sms"></i>Whatsapp: +234 9015362533</a></li>
                                            <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#a5cccbc3cae5c0ddc4c8d5c9c08bc6cac8"><i className="far fa-envelope"></i><span className="__cf_email__" data-cfemail="nigeria@automotorauctions.com">nigeria@automotorauctions.com</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box list">

                                    <ul className="footer-contact">
                                        <li><a href="#"> <i className="far fa-location"></i>5 Isaac John Street, G.R.A. Ikeja, Lagos, Nigeria
                                        </a></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <hr />
                        <div className="row footer-widget-wrapper">
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box about-us">
                                    <h4 className="footer-widget-title">International Sales Department</h4>
                                    <div class="header-top-contact">
                                        <ul>
                                            <li><a href="#"> Mon - Fri (09AM - 07PM) View All Office Locations</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box list">
                                    {/* <h4 className="footer-widget-title">Auto Auctions</h4> */}
                                    <div className="footer-widget-box about-us">
                                        <ul className="footer-contact">
                                            <li><a href="tel:+21236547898"><i className="far fa-phone"></i>Call us: +1 (786) 936 - 4708</a></li>
                                            <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#a5cccbc3cae5c0ddc4c8d5c9c08bc6cac8"><i className="far fa-envelope"></i><span className="__cf_email__" data-cfemail="bcd5d2dad3fcd9c4ddd1ccd0d992dfd3d1">info@automotorauctions.com</span></a></li>
                                            <li><i className="far fa-whatsapp"></i>Whatsapp:  +1 (786) 936 - 4708</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-4">
                                <div className="footer-widget-box list">

                                    <ul className="footer-contact">
                                        <li><a href="#"> <i className="far fa-location"></i>30 N Gould St Ste N Sheridan, WY 82801</a></li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <hr />
                        <div className="row footer-widget-wrapper pb-70">
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box about-us">
                                    <h4 className="footer-widget-title">Vechile Finder</h4>
                                    <ul className="footer-list">
                                        <li><a href="#"><i className="fas fa-caret-right"></i> FAQ's</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Affiliates</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Booking Tips</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sell Vehicles</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Contact Us</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sitemap</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Auto Auctions</h4>
                                    <ul className="footer-list">
                                        <li><a href="#"><i className="fas fa-caret-right"></i> About Us</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Update News</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Testimonials</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Terms Of Service</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Privacy policy</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Our Dealers</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Support Center</h4>
                                    <ul className="footer-list">
                                        <li><a href="#"><i className="fas fa-caret-right"></i> FAQ's</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Affiliates</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Booking Tips</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sell Vehicles</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Contact Us</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sitemap</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Services</h4>
                                    <ul className="footer-list">
                                        <li><a href="#"><i className="fas fa-caret-right"></i> FAQ's</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Affiliates</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Booking Tips</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sell Vehicles</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Contact Us</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sitemap</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Newsletter</h4>
                                    <ul className="footer-list">
                                        <li><a href="#"><i className="fas fa-caret-right"></i> FAQ's</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Affiliates</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Booking Tips</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sell Vehicles</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Contact Us</a></li>
                                        <li><a href="#"><i className="fas fa-caret-right"></i> Sitemap</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-2">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Company Information</h4>
                                    <div className="footer-widget-box about-us">
                                        <ul className="footer-contact">
                                            <li><a href="tel:+21236547898"><i className="far fa-phone"></i>+2 123 654 7898</a></li>
                                            <li><i className="far fa-map-marker-alt"></i>25/B Milford Road, New York</li>
                                            <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#a5cccbc3cae5c0ddc4c8d5c9c08bc6cac8"><i className="far fa-envelope"></i><span className="__cf_email__" data-cfemail="bcd5d2dad3fcd9c4ddd1ccd0d992dfd3d1">[email&#160;protected]</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 align-self-center">
                                        <ul className="footer-social">
                                            <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <p className="copyright-text">
                                    &copy; Copyright <span id="date"></span> <a href="#"> AutoMotor Auctions LLC </a> All Rights Reserved.
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer