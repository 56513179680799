/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
import Card from '../Card/Card';

const ListingSlider = ({ data }) => {
    return (
        <>
            <Swiper
                slidesPerView={4}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="listingSlider"
            >
                {data?.map((e) => {
                    return (
                        <>
                            <SwiperSlide>
                                <Card data={e} />
                            </SwiperSlide>
                        </>
                    )
                })}
                

            </Swiper>
        </>
    )
}

export default ListingSlider