/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

const ListingSliderSM = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="listingSlider"
            >
                {data?.map((data) => {
                    return (
                        <>
                            <SwiperSlide>
                                <div class="car-item wow fadeInUp" data-wow-delay=".25s">
                                    <div class="car-img">
                                        <span class="car-status status-1">Used</span>
                                        <img src={data?.carImages?.length > 0 ? data?.carImages[0] : "/assets/img/car/01.jpg"} alt="" />
                                        <div class="car-btns">
                                            <a href="#"><i class="far fa-heart"></i></a>
                                            <a href="#"><i class="far fa-arrows-repeat"></i></a>
                                        </div>
                                    </div>
                                    <div class="car-content">
                                        <div class="car-top">
                                            <h4><a href="#">{data?.vehicleDetails?.length > 0 && data?.vehicleDetails[0]?.TitleCode}</a></h4>
                                            <div class="car-rate">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <span>5.0 (58.5k Review)</span>
                                            </div>
                                        </div>
                                        <ul class="car-list">
                                            <li><i class="far fa-steering-wheel"></i>{data?.vehicleDetails?.length > 0 && data?.vehicleDetails[0]?.Transmission}</li>
                                            <li><i class="far fa-road"></i>{data?.vehicleDetails?.length > 0 && data?.vehicleDetails[0]?.EngineType}</li>
                                            <li><i class="far fa-car"></i>Model: 2023</li>
                                            <li><i class="far fa-gas-pump"></i>{data?.vehicleDetails?.length > 0 && data?.vehicleDetails[0]?.Fuel}</li>
                                        </ul>
                                        <div class="car-footer">
                                            <span class="car-price">{data?.bidInformation?.length > 0 && data?.bidInformation[0]?.CurrentBid}</span>
                                            <button class="theme-btn" onClick={()=>navigate(`/detail/${data?.id}`)}><span class="far fa-eye"></span>Details</button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </>
                    )
                })}

            </Swiper>
        </>
    )
}

export default ListingSliderSM