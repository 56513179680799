import React from 'react'

const ReviewCard = () => {
    return (
        <>
            <div class="testimonial-single">
                <div class="testimonial-content">
                    <div class="testimonial-author-img">
                        <img src="assets/img/testimonial/01.jpg" alt="" />
                    </div>
                    <div class="testimonial-author-info">
                        <h4>Sylvia H Green</h4>
                        <p>Customer</p>
                    </div>
                </div>
                <div class="testimonial-quote">
                    <span class="testimonial-quote-icon"><i class="flaticon-quote"></i></span>
                    <p>
                        There are many variations of passages available but the majority have
                        suffered to the alteration in some injected.
                    </p>
                </div>
                <div class="testimonial-rate">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
            </div>
        </>
    )
}

export default ReviewCard