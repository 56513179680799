/* eslint-disable no-unused-vars */
import React from 'react'
import MainSlider from '../../Components/Slider/MainSlider'
import HomeFilter from '../../Components/Filter/HomeFilter'
import BrandSlider from '../../Components/Slider/BrandSlider'
import Services from '../../Components/Services/Services'
import Listing from '../../Components/Listing/Listing'
import CopartData from '../../Components/Copart/CopartData'
import ReviewSlider from '../../Components/Slider/ReviewSlider'
import { useQuery } from "@apollo/client";
import { GET_DATA } from '../../Query/ListingQuery';

const Home = () => {
    const { data, loading, error } = useQuery(GET_DATA)

    // console.log(data,"data")
     
      
    return (
        <>

            <MainSlider />
            <HomeFilter />
            <BrandSlider />
            <Listing data={data?.vehicles.slice(0 , 4)}/>
            <Services />
            <CopartData />
            <ReviewSlider />

        </>
    )
}

export default Home