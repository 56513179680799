/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { BUYEREGISTER } from '../../Mutation/AuthMutation';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import {Link, useNavigate} from "react-router-dom"

const RegisterForm = () => {
    const [Image, setImage] = useState(null)
    const [createBuyer, { data, loading, error }] = useMutation(BUYEREGISTER);
const navigate = useNavigate()
    const handlerChange = (e) => {
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
        try {
            const media =new FormData();
            media.append('media', e.target.files[0])
            axios.post('http://192.168.114.11:4000/upload-media', media).then((response) => {
                console.log(response, "media api")
                setImage(response?.data?.data[0]?.name)
            })
        } catch (error) {
            console.log(error, "media api")
        }
    };

    const registerSchema = Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        username: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            dob: "",
            country: "",
            phone: "",
            profilePic: null
        },
        validationSchema: registerSchema,
        onSubmit: (values) => {
            const { email, password, firstName, lastName, dob, country, phone,username } = values;
            createBuyer({ variables: { email, password, firstName, lastName, dob, country, phone, profilePic: Image, username } })
                .then((response) => {
                    if (response && response?.data) {
                        toast.success("Register SuccessFully")
                        navigate('/login')
                    }
                });
            console.log(values.errors, "formErrors");

        },
    });

    return (
        <>
            <div class="site-breadcrumb" style={{ background: "url(/assets/img/breadcrumb/01.jpg)" }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Register</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Register</li>
                    </ul>
                </div>
            </div>

            <div class="login-area py-120">
                <div class="container">
                    <div class="col-md-8 mx-auto">
                        <div class="login-form">
                            <div class="login-header">
                                <img src="/assets/img/logo/1.png" alt="" />
                                <p>Create your Auto Motor account</p>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>First Name</label>
                                            <input type="text" class="form-control" placeholder="First Name" name='firstName'
                                                onChange={formik.handleChange}
                                                value={formik.values.firstName}
                                            />
                                            {formik.errors.firstName && (
                                                <div className="error">{formik.errors.firstName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Last Name</label>
                                            <input type="text" class="form-control" placeholder="Last Name" name='lastName'
                                                onChange={formik.handleChange}
                                                value={formik.values.lastName}
                                            />
                                            {formik.errors.lastName && (
                                                <div className="error">{formik.errors.lastName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>User Name</label>
                                            <input type="text" class="form-control" placeholder="User Name" name='username'
                                                onChange={formik.handleChange}
                                                value={formik.values.username}
                                            />
                                            {formik.errors.username && (
                                                <div className="error">{formik.errors.username}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control" placeholder="Email" name='email'
                                                onChange={formik.handleChange}
                                                value={formik.values.email}
                                            />
                                            {formik.errors.email && (
                                                <div className="error">{formik.errors.email}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" class="form-control" placeholder="Password" name='password'
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                            />
                                            {formik.errors.password && (
                                                <div className="error">{formik.errors.password}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Date Of Birth</label>
                                            <input type="date" class="form-control" placeholder="" name='dob'
                                                onChange={formik.handleChange}
                                                value={formik.values.dob}
                                            />
                                            {formik.errors.dob && (
                                                <div className="error">{formik.errors.dob}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Phone</label>
                                            <input type="text" class="form-control" placeholder="" name='phone'
                                                onChange={formik.handleChange}
                                                value={formik.values.phone}
                                            />
                                            {formik.errors.phone && (
                                                <div className="error">{formik.errors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div class="form-group">
                                            <label>Country</label>
                                            <input type="text" class="form-control" placeholder="" name='country'
                                                onChange={formik.handleChange}
                                                value={formik.values.country}
                                            />
                                            {formik.errors.country && (
                                                <div className="error">{formik.errors.country}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <div class="form-group">
                                            <label>Profile Photo</label>
                                            <input type="file" class="form-control" placeholder="" name='profilePic'
                                                onChange={handlerChange}
                                                value={formik.values.profilePic}
                                            />
                                            {formik.errors.profilePic && (
                                                <div className="error">{formik.errors.profilePic}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <button type="submit" class="theme-btn"><i class="far fa-paper-plane"></i>
                                            {loading ? "...." :
                                                'Register'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div class="login-footer">
                                <p>Already have an account? <Link to="/login">Login</Link>
                                    
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterForm