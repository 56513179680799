/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
import ReviewCard from '../Card/ReviewCard';

const ReviewSlider = () => {
    return (
        <>
            <div class="testimonial-area bg py-120">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline"><i class="flaticon-drive"></i> Testimonials</span>
                                <h2 class="site-title">What Our Client <span>Say's</span></h2>
                                <div class="heading-divider"></div>

                                <Swiper
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="ReviewSlider"
                                >
                                    <SwiperSlide>
                                        <ReviewCard />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <ReviewCard />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default ReviewSlider