/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
import { Pagination, Navigation } from "swiper/modules";
import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FaStar } from "react-icons/fa";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './Detail.css';
import { useMutation, useQuery } from "@apollo/client";
import { GET_DATA } from '../../Query/ListingQuery';
import { useParams } from 'react-router-dom'
import { BUYEREMAIL } from "../../Mutation/EmailMutation";
import toast from 'react-hot-toast';

const Detail = () => {
  const { id } = useParams()
  const { data, loading, error } = useQuery(GET_DATA)
  const IsAuthentic = localStorage.getItem('accessToken')
  const [sentEmail, { data: emailData, loading: EmailLoading, error: emailError }] = useMutation(BUYEREMAIL);

  const Singledata = data?.vehicles?.find((e) => e?.id == id)
  const mergedArray = [];

  if (Singledata?.vehicleDescription?.[0]) {
    const vehicleDescriptionEntries = Object.entries(Singledata.vehicleDescription[0])
      .filter(([key]) => key !== "RestraintSystem" && key !== "__typename");

    mergedArray.push(...vehicleDescriptionEntries.filter(([key, value]) =>
      !(Singledata.vehicleDetails?.[0]?.hasOwnProperty(key) && Singledata.vehicleDetails[0][key] === value)
    ));
  }

  if (Singledata?.vehicleDetails?.[0]) {
    const vehicleDetailsEntries = Object.entries(Singledata.vehicleDetails[0])
      .filter(([key]) => key !== "RestraintSystem" && key !== "__typename");

    mergedArray.push(...vehicleDetailsEntries.filter(([key]) =>
      !(Singledata.vehicleDescription?.[0]?.hasOwnProperty(key))
    ));
  }
  console.log(mergedArray, "mergedArray")

  const SentEmail = () => {
    sentEmail({ variables: { url: `https://automotor.dev-bt.xyz/detail/${id}` } })
      .then((response) => {
        if (response && response?.data) {
          console.log(response, "email");
          toast.success("Email Sent SuccessFully")
        }
      });
  }

  console.log(Singledata, 'Singledata')
  return (
    <>
      <div className="container mt-5 pb-4">
        <div className="row">
          <div className="col-lg-8 col-md-6 col-sm-12 h-100">

            {/* ////////////   Swiper Start ////////////// */}

            <Swiper
              //   pagination={{
              //     type: "fraction",
              //   }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="detailSwiper rounded"
            >
              {Singledata?.carImages?.map((e, i) => {
                return (
                  <>
                    <SwiperSlide key={i}>
                      <img
                        src={e}
                        alt="te"
                      />
                    </SwiperSlide>
                  </>
                )
              })}

            </Swiper>

            {/* ///////////////////////// Swiper End ///////////// */}
            {IsAuthentic &&
              <div className="d-flex mt-4">
                <div>
                  <button className="theme-btn">
                    Request A Physical Inspection
                  </button>
                </div>

                <div className="ms-4">
                  <button className="theme-btn">
                    Request a Vehicle History Report
                  </button>
                </div>
              </div>
            }
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-sm-12 col-lg-12">
                  <div class="car-single-widget">
                    <h4 class="mb-4">Key Information</h4>
                    <div class="car-key-info">
                      <div class="row">
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Odometer</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Odometer}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Keys</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Keys}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-speedometer"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Primary Damage</span>
                              <h6>{Singledata?.vehicleDetails[0]?.PrimaryDamage}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-settings"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Transmission</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Transmission}</h6>

                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Year</span>
                              <h6>N/A</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-gas-station"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Fuel Type</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Fuel}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Color</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Color}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Doors</span>
                              <h6>N/A</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Cylinders</span>
                              <h6>{Singledata?.vehicleDetails[0]?.Cylinders}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>Engine Size</span>
                              <h6>{Singledata?.vehicleDetails[0]?.EngineType}</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-6">
                          <div class="car-key-item">
                            <div class="car-key-icon">
                              <i class="flaticon-drive"></i>
                            </div>
                            <div class="car-key-content">
                              <span>VIN</span>
                              <h6>{Singledata?.vehicleDetails[0]?.VIN}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white px-4 py-2">
                    {/* <h4 class="mb-3">Car Features</h4>
                    <div class="row mb-3">
                      <div class="col-lg-4">
                        <ul class="car-single-list">
                          <li>
                            <i class="far fa-check-circle"></i> Multi-zone A/C
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Heated front seats
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Navigation system
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Leather seats
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-4">
                        <ul class="car-single-list">
                          <li>
                            <i class="far fa-check-circle"></i> Premium sound system
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Bluetooth
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Andriod Auto
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Intermittent wipers
                          </li>
                        </ul>
                      </div>
                      <div class="col-lg-4">
                        <ul class="car-single-list">
                          <li>
                            <i class="far fa-check-circle"></i> Memory seat
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Adaptive Cruise
                            Control
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Cooled Seats
                          </li>
                          <li>
                            <i class="far fa-check-circle"></i> Keyles Start
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    {/* <h4 class="mb-4">Vehicle History</h4>
                    <div class="mb-4">
                      <ul class="car-single-list">
                        <li>
                          <i class="far fa-check-circle"></i> It is a long established
                          fact that a reader will be distracted{" "}
                        </li>
                        <li>
                          <i class="far fa-check-circle"></i> Sed perspic unde omnis
                          iste natus sit voluptatem accusantium
                        </li>
                        <li>
                          <i class="far fa-check-circle"></i> Explain to you how all
                          this mistaken idea of denouncing pleasure
                        </li>
                        <li>
                          <i class="far fa-check-circle"></i> Praising pain was born
                          will give account of the system
                        </li>
                      </ul>
                    </div> */}
                    <h4 class="mb-4">Location</h4>
                    <div class="car-single-map">
                      <div class="contact-map">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96708.34194156103!2d-74.03927096447748!3d40.759040329405195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a01c8df6fb3cb8!2sSolomon%20R.%20Guggenheim%20Museum!5e0!3m2!1sen!2sbd!4v1619410634508!5m2!1sen!2s"
                          style={{ border: "0" }}
                          allowfullscreen
                          loading="lazy"
                        ></iframe>
                      </div>
                    </div>
                  </div>


                  {/* <div className="bg-white px-4 py-2  mt-4 rounded">
                    <h4 className="mt-4">Customer Reviews</h4>
                    <div
                      className="border border-5 border-theme mt-4 ms-2 text-center rounded-circle"
                      style={{ width: "150px", height: "150px" }}
                    >
                      <div className="mt-4">
                        <p className="text-color">Overall Rating</p>
                        <h3 className="text-color">4.5</h3>
                        <p className="text-color">out of 5</p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div>
                        <div className="d-flex justify-content-between align-items-center w-50">
                          <div>
                            <h6>Comfort</h6>
                            <p>Excellent</p>
                          </div>
                          <div className="d-flex ">
                            <FaStar className="text-color" />
                            <h6 className="ms-2"> 5.0</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-50 mt-4">
                          <div>
                            <h6>Performance</h6>
                            <p>Excellent</p>
                          </div>
                          <div className="d-flex ">
                            <FaStar className="text-color" />
                            <h6 className="ms-2"> 5.0</h6>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center w-50 mt-4">
                          <div>
                            <h6>Exterior Styling</h6>
                            <p>Excellent</p>
                          </div>
                          <div className="d-flex ">
                            <FaStar className="text-color" />
                            <h6 className="ms-2"> 5.0</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="car-single-widget">
                    <div class="car-single-review">
                      <div class="blog-comments mb-0">
                        <h4>Reviews (05)</h4>
                        <div class="blog-comments-wrapper">
                          <div class="blog-comments-single">
                            <img src="assets/img/blog/com-1.jpg" alt="thumb" />
                            <div class="blog-comments-content">
                              <h5>Jesse Sinkler</h5>
                              <span>
                                <i class="far fa-clock"></i> January 31, 2023
                              </span>
                              <p>
                                At vero eos et accusamus et iusto odio dignissimos
                                ducimus qui blanditiis praesentium voluptatum deleniti
                                atque corrupti quos dolores et quas molestias
                                excepturi sint occaecati cupiditate non provident.
                              </p>
                              <a href="#">
                                <i class="far fa-reply"></i> Reply
                              </a>
                            </div>
                          </div>
                          <div class="blog-comments-single">
                            <img src="assets/img/blog/com-2.jpg" alt="thumb" />
                            <div class="blog-comments-content">
                              <h5>Daniel Wellman</h5>
                              <span>
                                <i class="far fa-clock"></i> January 31, 2023
                              </span>
                              <p>
                                At vero eos et accusamus et iusto odio dignissimos
                                ducimus qui blanditiis praesentium voluptatum deleniti
                                atque corrupti quos dolores et quas molestias
                                excepturi sint occaecati cupiditate non provident.
                              </p>
                              <a href="#">
                                <i class="far fa-reply"></i> Reply
                              </a>
                            </div>
                          </div>
                          <div class="blog-comments-single">
                            <img src="assets/img/blog/com-3.jpg" alt="thumb" />
                            <div class="blog-comments-content">
                              <h5>Kenneth Evans</h5>
                              <span>
                                <i class="far fa-clock"></i> January 31, 2023
                              </span>
                              <p>
                                At vero eos et accusamus et iusto odio dignissimos
                                ducimus qui blanditiis praesentium voluptatum deleniti
                                atque corrupti quos dolores et quas molestias
                                excepturi sint occaecati cupiditate non provident.
                              </p>
                              <a href="#">
                                <i class="far fa-reply"></i> Reply
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="blog-comments-form">
                          <h4>Leave A Review</h4>
                          <form action="#">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group car-review-rating">
                                  <label>Your Rating</label>
                                  <div>
                                    <i class="far fa-star"></i>
                                    <i class="far fa-star"></i>
                                    <i class="far fa-star"></i>
                                    <i class="far fa-star"></i>
                                    <i class="far fa-star"></i>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Your Name* "
                                    alt="fcd"
                                  />
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    type="email"
                                    class="form-control"
                                    placeholder="Your Email*"
                                    alt="fsa"
                                  />
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <textarea
                                    class="form-control"
                                    rows="5"
                                    placeholder="Your Comment*"
                                  ></textarea>
                                </div>
                                <button type="submit" class="theme-btn">
                                  <span class="far fa-paper-plane"></span> Submit
                                  Review
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>


            </div>
          </div>
          <div className="col-lg-4  col-md-6 col-sm-12">
            <div>
              <h1>{Singledata?.vehicleDescription?.length > 0 && Singledata?.vehicleDescription[0]?.Model} {Singledata?.vehicleDescription?.length > 0 && Singledata?.vehicleDescription[0]?.VehicleClass}</h1>
              <p className="text-secondary">
                {Singledata?.vehicleDescription?.length > 0 && Singledata?.vehicleDescription[0]?.RestraintSystem}
              </p>
              <p className="mt-1 text-secondary">{Singledata?.vehicleDescription?.length > 0 && Singledata?.vehicleDescription[0]?.Engine}</p>
              <h6 className="mt-2 text-secondary">Our Price</h6>
              <h3 className="mt-1">$ {Singledata?.price}</h3>
              {/* <p className="text-secondary">Instans Saving : $7000</p> */}
              <div className="mt-2 d-flex">
                <button className="theme-btn " onClick={SentEmail}>
                  Make An Offer Price
                </button>
                <button className=" ms-4 border border-secondary rounded px-3 py-2 ">
                  Shedule Test Drive
                </button>
              </div>
            </div>

            <div className="mt-4">
              <div class="card p-1" style={{ width: "28rem" }}>
                <div class="ms-3 mt-2">
                  <h6>Vehicles Information</h6>
                </div>
                <div className="d-flex mt-2">
                  <div>
                    <ul class="list-group list-group-flush">
                      {mergedArray?.length > 0 && mergedArray?.map(([key, value], index) => {
                        return (
                          <>
                            <li key={index} class="list-group-item">{key}</li>
                          </>
                        )
                      })}

                    </ul>
                  </div>
                  <div>
                    <ul class="list-group list-group-flush">
                      {mergedArray?.length > 0 && mergedArray?.map(([key, value], index) => {
                        return (
                          <>
                            <li class="list-group-item">{value == null ? 'N/A' : value}</li>
                          </>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div class="card p-1" style={{ width: "21rem" }}>
                <div class="ms-3 mt-2">
                  <h6>Sale Information</h6>
                </div>
                <div className="d-flex mt-2">
                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">LastUpdated</li>
                      <li class="list-group-item">SaleDate</li>
                      <li class="list-group-item">SaleLocation</li>
                      <li class="list-group-item">SaleName</li>
                    </ul>
                  </div>
                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">{Singledata?.saleInformation[0]?.LastUpdated == null ? 'N/A' : Singledata?.saleInformation[0]?.LastUpdated}</li>
                      <li class="list-group-item">{Singledata?.saleInformation[0]?.SaleDate == null ? 'N/A' : Singledata?.saleInformation[0]?.SaleDate}</li>
                      <li class="list-group-item">{Singledata?.saleInformation[0]?.SaleLocation == null ? 'N/A' : Singledata?.saleInformation[0]?.SaleLocation}</li>
                      <li class="list-group-item">{Singledata?.saleInformation[0]?.SaleName == null ? 'N/A' : Singledata?.saleInformation[0]?.SaleName}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div class="card p-1" style={{ width: "21rem" }}>
                <div class="ms-3 mt-2">
                  <h6>Bid Information</h6>
                </div>
                <div className="d-flex mt-2">
                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">CurrentBid</li>
                      <li class="list-group-item">SaleStatus</li>
                      <li class="list-group-item">TimeLeft</li>

                    </ul>
                  </div>
                  <div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">$ {Singledata?.bidInformation[0]?.CurrentBid == null ? 'N/A' : Singledata?.bidInformation[0]?.CurrentBid}</li>
                      <li class="list-group-item">{Singledata?.bidInformation[0]?.SaleStatus == null ? 'N/A' : Singledata?.bidInformation[0]?.SaleStatus}</li>
                      <li class="list-group-item">{Singledata?.bidInformation[0]?.TimeLeft == null ? 'N/A' : Singledata?.bidInformation[0]?.TimeLeft}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white px-4 py-2  mt-4 rounded">
          <h3 className="mt-4">Fees Calculator</h3>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between align-items-center mt-4">
              <div className="d-flex justify-content-between align-items-start flex-column">
                <h6>Transaction Fee</h6>
                <h5>$300</h5>
              </div>
              <div className="d-flex justify-content-between align-items-start flex-column">
                <h6>Documentation fee</h6>
                <h5>Flat $105</h5>
              </div>
              <div className="d-flex justify-content-between align-items-start flex-column">
                <h6>Auction Fees</h6>
                <h5>Comming Soon</h5>
              </div>
              <div className="d-flex justify-content-between align-items-start flex-column">
                <h6>International Bank Wire Fees</h6>
                <h5>$40</h5>
              </div>
              {IsAuthentic ?
                <div className="d-flex justify-content-between align-items-start flex-column">
                  <button className="theme-btn mt-4">
                    Shipping
                  </button>
                </div>
                :
                <div className="d-flex justify-content-between align-items-start flex-column">
                  <button className="theme-btn mt-4">
                    Shipping
                  </button>
                </div>
              }
            </div>
          </div>

          <div>
            <button className="theme-btn mt-4">
              Calculate
            </button>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <p>Monthly Payment : $3354</p>{" "}
            <p className="ms-4">Total amount to Pay : $1254</p>{" "}
            <p className="ms-4">Total Intrest Payment : $1342</p>
          </div>
        </div>
      </div >

      <div className="container">
        <div className="row">
          <div className="col-12">
            <div class="car-single-related mt-5">
              <h3 class="mb-30">Related Listing</h3>
              <div class="row">
                <div class="col-md-6 col-lg-4 col-xl-3">
                  <div class="car-item">
                    <div class="car-img">
                      <span class="car-status status-1">Used</span>
                      <img src="/assets/img/car/01.jpg" alt="sr" />
                      <div class="car-btns">
                        <a href="#">
                          <i class="far fa-heart"></i>
                        </a>
                        <a href="#">
                          <i class="far fa-arrows-repeat"></i>
                        </a>
                      </div>
                    </div>
                    <div class="car-content">
                      <div class="car-top">
                        <h4>
                          <a href="#">Mercedes Benz Car</a>
                        </h4>
                        <div class="car-rate">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <span>5.0 (58.5k Review)</span>
                        </div>
                      </div>
                      <ul class="car-list">
                        <li>
                          <i class="far fa-steering-wheel"></i>Automatic
                        </li>
                        <li>
                          <i class="far fa-road"></i>10.15km / 1-litre
                        </li>
                        <li>
                          <i class="far fa-car"></i>Model: 2023
                        </li>
                        <li>
                          <i class="far fa-gas-pump"></i>Hybrid
                        </li>
                      </ul>
                      <div class="car-footer">
                        <span class="car-price">$45,620</span>
                        <a href="#" class="theme-btn">
                          <span class="far fa-eye"></span>Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                  <div class="car-item">
                    <div class="car-img">
                      <img src="/assets/img/car/02.jpg" alt="f" />
                      <div class="car-btns">
                        <a href="#">
                          <i class="far fa-heart"></i>
                        </a>
                        <a href="#">
                          <i class="far fa-arrows-repeat"></i>
                        </a>
                      </div>
                    </div>
                    <div class="car-content">
                      <div class="car-top">
                        <h4>
                          <a href="#">Yellow Ferrari 458</a>
                        </h4>
                        <div class="car-rate">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <span>5.0 (58.5k Review)</span>
                        </div>
                      </div>
                      <ul class="car-list">
                        <li>
                          <i class="far fa-steering-wheel"></i>Automatic
                        </li>
                        <li>
                          <i class="far fa-road"></i>10.15km / 1-litre
                        </li>
                        <li>
                          <i class="far fa-car"></i>Model: 2023
                        </li>
                        <li>
                          <i class="far fa-gas-pump"></i>Hybrid
                        </li>
                      </ul>
                      <div class="car-footer">
                        <span class="car-price">$90,250</span>
                        <a href="#" class="theme-btn">
                          <span class="far fa-eye"></span>Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                  <div class="car-item">
                    <div class="car-img">
                      <img src="/assets/img/car/03.jpg" alt="fnd" />
                      <div class="car-btns">
                        <a href="#">
                          <i class="far fa-heart"></i>
                        </a>
                        <a href="#">
                          <i class="far fa-arrows-repeat"></i>
                        </a>
                      </div>
                    </div>
                    <div class="car-content">
                      <div class="car-top">
                        <h4>
                          <a href="#">Black Audi Q7</a>
                        </h4>
                        <div class="car-rate">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <span>5.0 (58.5k Review)</span>
                        </div>
                      </div>
                      <ul class="car-list">
                        <li>
                          <i class="far fa-steering-wheel"></i>Automatic
                        </li>
                        <li>
                          <i class="far fa-road"></i>10.15km / 1-litre
                        </li>
                        <li>
                          <i class="far fa-car"></i>Model: 2023
                        </li>
                        <li>
                          <i class="far fa-gas-pump"></i>Hybrid
                        </li>
                      </ul>
                      <div class="car-footer">
                        <span class="car-price">$44,350</span>
                        <a href="#" class="theme-btn">
                          <span class="far fa-eye"></span>Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 col-xl-3">
                  <div class="car-item">
                    <div class="car-img">
                      <span class="car-status status-2">New</span>
                      <img src="/assets/img/car/04.jpg" alt="fe" />
                      <div class="car-btns">
                        <a href="#">
                          <i class="far fa-heart"></i>
                        </a>
                        <a href="#">
                          <i class="far fa-arrows-repeat"></i>
                        </a>
                      </div>
                    </div>
                    <div class="car-content">
                      <div class="car-top">
                        <h4>
                          <a href="#">BMW Sports Car</a>
                        </h4>
                        <div class="car-rate">
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <i class="fas fa-star"></i>
                          <span>5.0 (58.5k Review)</span>
                        </div>
                      </div>
                      <ul class="car-list">
                        <li>
                          <i class="far fa-steering-wheel"></i>Automatic
                        </li>
                        <li>
                          <i class="far fa-road"></i>10.15km / 1-litre
                        </li>
                        <li>
                          <i class="far fa-car"></i>Model: 2023
                        </li>
                        <li>
                          <i class="far fa-gas-pump"></i>Hybrid
                        </li>
                      </ul>
                      <div class="car-footer">
                        <span class="car-price">$78,760</span>
                        <a href="#" class="theme-btn">
                          <span class="far fa-eye"></span>Details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Detail;
