/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = () => {
    const [Open, setOpen] = useState(false)
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('accessToken')

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    }
    return (
        <>
            <div class="header-top">
                <div class="container-fluid">
                    <div class="header-top-wrapper">
                        <div class="header-top-left">
                            <div className="nav-right">
                                <div className="search-btn">
                                    <button type="button" className="nav-right-link" onClick={() => setOpen(!Open)}><i className="far fa-search"></i></button>
                                </div>

                                <div className={`search-area ${Open ? 'open' : null}`}>
                                    <form action="#">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Type Keyword..." />
                                            <button type="submit" className="search-icon-btn"><i className="far fa-search"></i></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="header-top-right">
                            <div class="header-top-contact">
                                <ul>
                                    <li><a href="https://live.themewild.com/cdn-cgi/l/email-protection#a9c0c7cfc6e9ccd1c8c4d9c5cc87cac6c4"><i class="far fa-envelopes"></i>
                                        <span class="__cf_email__" data-cfemail="6900070f06290c11080419050c470a0604">[email&#160;protected]</span></a></li>
                                    <li><a href="tel:+21236547898"><i class="far fa-phone-volume"></i> +2 123 654 7898</a>
                                    </li>
                                    <li><a href="#"><i class="far fa-alarm-clock"></i> Mon - Fri (09AM - 07PM)</a></li>
                                </ul>
                            </div>
                            <div class="header-top-social">
                                <span>Follow Us: </span>
                                <a href="#"><i class="fab fa-facebook"></i></a>
                                <a href="#"><i class="fab fa-twitter"></i></a>
                                <a href="#"><i class="fab fa-instagram"></i></a>
                                <a href="#"><i class="fab fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-navigation">
                <nav className="navbar navbar-expand-lg">
                    <div className="container position-relative">

                        <div className="mobile-menu-right">

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-mobile-icon"><i className="far fa-bars"></i></span>
                            </button>
                            <Link className="navbar-brand-sm d-lg-none d-block" to="/">
                                <img src="/assets/img/logo/1.png" alt="logo" className='img-fluid' />
                            </Link>
                        </div>
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <Link className="navbar-brand d-lg-block d-none" to="/">
                                    <img src="/assets/img/logo/1.png" alt="logo" className='img-fluid' />
                                </Link>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle active" to="#" data-bs-toggle="dropdown">How It Works</Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="dashboard.html">How to Buy</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Vehicle Inspection</a></li>
                                        <li><a className="dropdown-item" href="add-listing.html">Add Shipping </a></li>
                                        <li><a className="dropdown-item" href="profile-favorite.html">Local Clearing</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">Vehicle Search</Link>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/copartListing">Copart Inventory</Link></li>
                                        <li><Link className="dropdown-item" to="/iaaiListing">IAAI Inventory</Link></li>
                                        <li><Link className="dropdown-item" to="/allListing">All Inventory </Link></li>
                                        <li><a className="dropdown-item" href="profile-favorite.html">Alerts</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">Shipping</Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="dashboard.html">Request Shipping</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Shipping FAQs</a></li>

                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">Payment</Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="dashboard.html">Deposit</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Purchase</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Auction Fees</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Shipping</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Refunds</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Local clearing</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Payment FAQ’s</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">Resource Center</Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="dashboard.html">Help Center</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Video Guides</a></li>
                                        <li><a className="dropdown-item" href="profile.html">FAQs</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Terms and Policies</a></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" data-bs-toggle="dropdown">Support</Link>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="dashboard.html">Inquiries</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Complaints</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Contact Us</a></li>
                                        <li><a className="dropdown-item" href="profile.html">Affiliates and Partnerships</a></li>
                                    </ul>
                                </li>


                            </ul>
                            <div className="nav-right">

                                {/* <div className="cart-btn">
                                    <a href="#" className="nav-right-link"><i className="far fa-cart-plus"></i><span>0</span></a>
                                </div> */}
                                {token ?

                                    <div className="nav-right-btn mt-2">
                                        <Nav>
                                            <NavDropdown
                                                id="nav-dropdown-dark-example"
                                                title={user?.email}
                                                menuVariant="dark"
                                            >
                                                <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                                            </NavDropdown>
                                        </Nav>
                                    </div>
                                    :
                                    <>
                                        <div className="nav-right-btn mt-2">
                                            <Link to="/login" className="theme-btn">Login</Link>
                                        </div>
                                        <div className="nav-right-btn mt-2">
                                            <Link to="/Register" className="theme-btn">Signup</Link>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>


                    </div >
                </nav >
            </div >
        </>
    )
}

export default Header