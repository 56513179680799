/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './Card.css';
import { useNavigate } from "react-router-dom"

const Card = ({ data }) => {
    const navigate = useNavigate()
    return (
        <>
            <div className=''>
                <div className='row'>
                    <div className='col-lg-6 '>
                        <div className=''>
                            <div class="blog-slider">

                                <div class="blog-slider__title">{data?.title}</div>
                                {/* <span class="blog-slider__code mb-5">{data?.vehicleWebsite}</span>/ */}
                                <div class="blog-slider__img">
                                    <img src={data?.carImages[0]} alt="" />
                                </div>
                                <div class="blog-slider__content">
                                    <div className='row justify-content-center mb-4'>
                                        <div className='col-md-4 px-0'>
                                            <h6 className='lh-xl'>Model year:</h6>
                                            <h6 className='lh-xl'>Lot #:</h6>
                                            <h6 className='lh-xl'>Location:</h6>
                                        </div>
                                        <div className='col-md-4 px-0'>
                                            <h6 className='lh-xl'>2020</h6>
                                            <h6 className='lh-xl'>{data?.vehicleDetails[0]?.LotNumber}</h6>
                                            <h6 className='lh-lg fs-6'>{data?.saleInformation[0]?.SaleLocation}</h6>
                                        </div>
                                    </div>
                                    <h2 className='mt-2'>{data?.bidInformation[0]?.CurrentBid}</h2>
                                    <p className='fs-6 text-dark'>Current Bid</p>
                                    <button className='theme-btn mt-3' onClick={() => navigate(`/detail/${data?.id}`)}>Order Now</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default Card;