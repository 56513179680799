import RegisterForm from "../../Components/Auth/RegisterForm"


export const Register = () => {

    return (
        <>
            <RegisterForm />
        </>
    )
}
