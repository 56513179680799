import { gql } from '@apollo/client';

// Define your GraphQL query
export const GET_DATA = gql`
query Vehicles($skip: Int, $take: Int, $type: String) {
  vehicles(skip: $skip, take: $take, type: $type) {
    title
    price
    carImages
    vehicleDetails {
      LotNumber
      VIN
      TitleCode
      Odometer
      PrimaryDamage
      Cylinders
      BodyStyle
      Color
      EngineType
      Transmission
      Drive
      VehicleType
      Fuel
      Keys
      Highlights
      Notes
    }
    bidInformation {
      SaleStatus
      TimeLeft
      CurrentBid
      EligibilityStatus
    }
    saleInformation {
      SaleName
      SaleLocation
      SaleDate
      LastUpdated
    }
    vehicleDescription {
      VIN
      Vehicle
      BodyStyle
      Engine
      Transmission
      DriveLineType
      FuelType
      Cylinders
      RestraintSystem
      ExteriorInterior
      Options
      ManufacturedIn
      VehicleClass
      Model
    }
    make {
      id
      name
    }
    vehicleWebsite
    id
  }
}  
`;

