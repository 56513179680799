import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './Pages/Home/Home';
import Detail from './Pages/Listing/Detail';
import Login from './Pages/Auth/Login';
import { Toaster } from 'react-hot-toast';
import { Register } from './Pages/Auth/Register';
import CopartListing from './Pages/Listing/Copart/CopartListing';
import IaaiListing from './Pages/Listing/IAAI/IaaiListing';
import AllListing from './Pages/Listing/AllListing';

function App() {
  return (
    <>
      <BrowserRouter>
        <Toaster
          position="top-right"
          reverseOrder={false}
        />
        <Layout>
          <Routes>
            <Route path="/Register" element={<Register />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/copartListing" element={<CopartListing />} />
            <Route exact path="/iaaiListing" element={<IaaiListing />} />
            <Route exact path="/allListing" element={<AllListing />} />
            <Route exact path="/detail/:id" element={<Detail />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
