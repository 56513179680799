/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

const MainSlider = () => {
    return (
        <>
            <div className='container-fluid'>
                <Swiper
                    direction={'vertical'}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div class="hero-single">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-content">
                                            <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                                Auto Motor!</h6>
                                            <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                                Best Way To Find Your <span>Dream</span> Car
                                            </h1>
                                            <p data-animation="fadeInLeft" data-delay=".75s">
                                                There are many variations of passages orem psum available but the majority have
                                                suffered alteration in some form by injected humour.
                                            </p>
                                            <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                                <a href="#" class="theme-btn">About More<i class="fas fa-arrow-right-long"></i></a>
                                                <a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right-long"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-right">
                                            <div class="hero-img">
                                                <img src="/assets/img/slider/hero-1.png" alt data-animation="fadeInRight" data-delay=".25s" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="hero-single" >
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-content">
                                            <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Auto Motor!</h6>
                                            <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                                Best Way To Find Your <span>Dream</span> Car
                                            </h1>
                                            <p data-animation="fadeInLeft" data-delay=".75s">
                                                There are many variations of passages orem psum available but the majority have
                                                suffered alteration in some form by injected humour.
                                            </p>
                                            <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                                <a href="#" class="theme-btn">About More<i class="fas fa-arrow-right-long"></i></a>
                                                <a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right-long"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-right">
                                            <div class="hero-img">
                                                <img src="/assets/img/slider/hero-2.png" alt data-animation="fadeInRight" data-delay=".25s" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div class="hero-single" >
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-content">
                                            <h6 class="hero-sub-title" data-animation="fadeInUp" data-delay=".25s">Welcome To
                                            Auto Motor!</h6>
                                            <h1 class="hero-title" data-animation="fadeInRight" data-delay=".50s">
                                                Best Way To Find Your <span>Dream</span> Car
                                            </h1>
                                            <p data-animation="fadeInLeft" data-delay=".75s">
                                                There are many variations of passages orem psum available but the majority have
                                                suffered alteration in some form by injected humour.
                                            </p>
                                            <div class="hero-btn" data-animation="fadeInUp" data-delay="1s">
                                                <a href="#" class="theme-btn">About More<i class="fas fa-arrow-right-long"></i></a>
                                                <a href="#" class="theme-btn theme-btn2">Learn More<i class="fas fa-arrow-right-long"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <div class="hero-right">
                                            <div class="hero-img">
                                                <img src="/assets/img/slider/hero-4.png" alt data-animation="fadeInRight" data-delay=".25s" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                </Swiper >
            </div>
        </>
    )
}

export default MainSlider