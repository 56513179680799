import React from 'react'

const CorpartCard = () => {
    return (
        <>
            <div className='container-fluid px-0 my-5'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='bg-corpart'></div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='copart-card'>
                            <div className='content'>
                                <h1 className='mb-3'>Get Your Dream Car Today!</h1>
                                <p>Copart is a global leader in 100% online car auctions featuring used, wholesale and repairable vehicles. We make it easy for Members to find, bid on, and win vehicles like classic cars, boats, repo cars, ATVs, exotics, motorcycles and more.

                                    As a global used car auction company, Copart puts the power to bid and win into your hands. Sign up for a Basic or Premier Membership to start bidding and winning used car auctions.</p>

                                <button className='theme-btn mt-3'>Become a Member</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CorpartCard