/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { NetworkStatus, useQuery } from "@apollo/client";
import { GET_DATA } from '../../../Query/ListingQuery';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../../../Components/Loader/Loader';

const IaaiListing = () => {
    const [isMoreData, setIsMoreData] = useState(true)
    const navigate = useNavigate()
    const { data, loading, error, fetchMore, networkStatus } = useQuery(GET_DATA, {
        variables: {
            skip: 0,
            take: 21,
            type: "iaai"
        }
    });
    const IAAI = data?.vehicles || []
    console.log(data?.vehicles, "data")

    const handleFetchMore = useCallback(() => {
        if (NetworkStatus.ready === networkStatus && isMoreData) {
            fetchMore({
                variables: {
                    skip: IAAI?.length,
                    take: 21,
                    type: "iaai"
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    setIsMoreData(
                        fetchMoreResult && fetchMoreResult.IAAI?.length !== 0
                    );
                    if (!fetchMoreResult) {
                        return prev;
                    }
                    return {
                        ...prev,
                        vehicles: [
                            ...prev?.vehicles,
                            ...fetchMoreResult?.vehicles,
                        ],
                    };
                },
            });
        }
    }, [data?.vehicles?.length, fetchMore, isMoreData, networkStatus]);

    useEffect(() => {
        window.addEventListener("scroll", function () {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
                console.log("Last page");
                handleFetchMore(); // Show loading spinner and make fetch request to api
            }
        });
    }, [handleFetchMore]);

    return (
        <>
            <section>
                <div className='container'>
                    <InfiniteScroll
                        dataLength={IAAI?.length || []} //This is important field to render the next data
                        next={handleFetchMore}
                        hasMore={isMoreData}
                        inverse={true}
                        loader={<Loader />}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }

                    >
                        <div className='row'>
                            {IAAI?.map((e) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <div class="car-item wow fadeInUp" data-wow-delay=".25s">
                                                <div class="car-img">
                                                    <span class="car-status status-1">Used</span>
                                                    <img src={e?.carImages?.length > 0 ? e?.carImages[0] : '/assets/img/car/01.jpg'} alt="" />
                                                    <div class="car-btns">
                                                        <a href="#"><i class="far fa-heart"></i></a>
                                                        <a href="#"><i class="far fa-arrows-repeat"></i></a>
                                                    </div>
                                                </div>
                                                <div class="car-content">
                                                    <div class="car-top">
                                                        <h4><a href="#">{e?.vehicleDescription?.length > 0 && e?.vehicleDescription[0]?.VehicleClass} {e?.vehicleDescription?.length > 0 && e?.vehicleDescription[0]?.Model}</a></h4>
                                                        <div class="car-rate">
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <i class="fas fa-star"></i>
                                                            <span>5.0 (58.5k Review)</span>
                                                        </div>
                                                    </div>
                                                    <ul class="car-list">
                                                        <li><i class="far fa-steering-wheel"></i>{e?.vehicleDescription?.length > 0 && e?.vehicleDescription[0]?.Transmission}</li>
                                                        <li><i class="far fa-road"></i>{e?.vehicleDescription?.length > 0 && e?.vehicleDescription[0]?.Engine}</li>
                                                        <li><i class="far fa-car"></i>Model: 2023</li>
                                                        <li><i class="far fa-gas-pump"></i>{e?.vehicleDescription?.length > 0 && e?.vehicleDescription[0]?.FuelType}</li>
                                                    </ul>
                                                    <div class="car-footer">
                                                        <span class="car-price">{e?.bidInformation?.length > 0 && e?.bidInformation[0]?.CurrentBid}</span>
                                                        <button class="theme-btn" onClick={() => navigate(`/detail/${e?.id}`)}><span class="far fa-eye"></span>Details</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </div>
            </section >
        </>
    )
}

export default IaaiListing