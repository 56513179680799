import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Loader = () => {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className="card-item wow fadeInUp" data-wow-delay=".25s">
                            <div className="car-img">
                                <Skeleton height={200} />
                                <div className="car-btns">
                                    <Skeleton circle={true} width={40} height={40} />
                                    <Skeleton circle={true} width={40} height={40} />
                                </div>
                            </div>
                            <div className="car-content">
                                <div className="car-top">
                                    <h4><Skeleton /></h4>
                                    <div className="car-rate">
                                        <Skeleton count={5} />
                                        <span><Skeleton /></span>
                                    </div>
                                </div>
                                <ul className="car-list">
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                </ul>
                                <div className="car-footer">
                                    <span><Skeleton /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="card-item wow fadeInUp" data-wow-delay=".25s">
                            <div className="car-img">
                                <Skeleton height={200} />
                                <div className="car-btns">
                                    <Skeleton circle={true} width={40} height={40} />
                                    <Skeleton circle={true} width={40} height={40} />
                                </div>
                            </div>
                            <div className="car-content">
                                <div className="car-top">
                                    <h4><Skeleton /></h4>
                                    <div className="car-rate">
                                        <Skeleton count={5} />
                                        <span><Skeleton /></span>
                                    </div>
                                </div>
                                <ul className="car-list">
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                </ul>
                                <div className="car-footer">
                                    <span><Skeleton /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className="card-item wow fadeInUp" data-wow-delay=".25s">
                            <div className="car-img">
                                <Skeleton height={200} />
                                <div className="car-btns">
                                    <Skeleton circle={true} width={40} height={40} />
                                    <Skeleton circle={true} width={40} height={40} />
                                </div>
                            </div>
                            <div className="car-content">
                                <div className="car-top">
                                    <h4><Skeleton /></h4>
                                    <div className="car-rate">
                                        <Skeleton count={5} />
                                        <span><Skeleton /></span>
                                    </div>
                                </div>
                                <ul className="car-list">
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                    <li><Skeleton /></li>
                                </ul>
                                <div className="car-footer">
                                    <span><Skeleton /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Loader