import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Listing.css';
import ListingSlider from '../Slider/ListingSlider';
import ListingSliderSM from '../Slider/ListingSliderSM';

const Listing = ({data}) => {

// console.log(data,"data")

    return (
        <>
            <div class="row mt-5 d-lg-block d-none">
                <div class="col-lg-6 mx-auto">
                    <div class="site-heading text-center">
                        <span class="site-title-tagline"><i class="flaticon-drive"></i> Popular Vehicle</span>
                        <div class="heading-divider"></div>

                    </div>
                </div>
            </div>
            <div className='container mb-4'>
                <Tabs
                    defaultActiveKey="Vehicles"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Bikes" title="Bikes">
                        <div className='d-lg-block d-none'>
                            <ListingSlider />
                        </div>
                        <div className='d-lg-none s-sm-block'>
                            <ListingSliderSM />
                        </div>
                    </Tab>
                    <Tab eventKey="Boats" title="Boats">
                        <div className='d-lg-block d-none'>
                            <ListingSlider />
                        </div>
                        <div className='d-lg-none s-sm-block'>
                            <ListingSliderSM />
                        </div>
                    </Tab>
                    <Tab eventKey="ATVs" title="ATVs">
                        <div className='d-lg-block d-none'>
                            <ListingSlider />
                        </div>
                        <div className='d-lg-none s-sm-block'>
                            <ListingSliderSM />
                        </div>
                    </Tab>
                    <Tab eventKey="Vehicles" title="Vehicles">
                        <div className='d-lg-block d-none'>
                            <ListingSlider data={data}/>
                        </div>
                        <div className='d-lg-none s-sm-block'>
                            <ListingSliderSM data={data}/>
                        </div>
                    </Tab>
                </Tabs>
            </div>

        </>
    )
}

export default Listing