import React from 'react'

const Services = () => {
    return (
        <>
            <div className="choose-area py-120 mt-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="choose-content">
                                <div className="site-heading wow fadeInDown" data-wow-delay=".25s">
                                    <span className="site-title-tagline text-dark justify-content-start">
                                        <i className="flaticon-drive"></i> Why Choose Us
                                    </span>
                                    <h2 className="site-title text-dark mb-10">We are dedicated <span>to provide</span> quality service</h2>
                                    <p className="text-dark">
                                        There are many variations of passages available but the majority have suffered alteration in some form going to use a passage by injected humour randomised words which don't look even slightly believable.
                                    </p>
                                </div>
                                <div className="choose-img wow fadeInUp" data-wow-delay=".25s">
                                    <img src="/assets/img/choose/01.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="choose-content-wrapper wow fadeInRight" data-wow-delay=".25s">
                                <div className="row">
                                    <div className="col-md-6 col-lg-6 mt-lg-5">
                                        <div className="choose-item">
                                            <span className="choose-count">01</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-car"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Official Representative</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                        <div className="choose-item">
                                            <span className="choose-count">03</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-chauffeur"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Live Bidding</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                        <div className="choose-item mb-lg-0">
                                            <span className="choose-count">05</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-drive-thru"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Low Transaction Fee</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                    <div className="choose-item mb-lg-0">
                                            <span className="choose-count">02</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-drive-thru"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Online Platform</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                        <div className="choose-item mb-lg-0">
                                            <span className="choose-count">04</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-online-payment"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Customer Support</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                        <div className="choose-item mb-lg-0">
                                            <span className="choose-count">06</span>
                                            <div className="choose-item-icon">
                                                <i className="flaticon-online-payment"></i>
                                            </div>
                                            <div className="choose-item-info">
                                                <h3>Transportaion To Nigeria</h3>
                                                <p>There are many variations of the passages available but the
                                                    majo have suffered fact that reader will be dist alteration.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Services