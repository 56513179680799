/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React from 'react'
import { BUYERLOGIN } from '../../Mutation/AuthMutation';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import { Link, useNavigate } from "react-router-dom"

const LoginForm = () => {

    const [userLogin, { data, loading, error }] = useMutation(BUYERLOGIN);
    const navigate = useNavigate()

    const loginSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Required"),
        password: Yup.string("Enter your password")
            .min(8, "Password should be of minimum 8 characters length")
            .required("Password is required"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: loginSchema,
        onSubmit: (values) => {
            const { email, password } = values;
            userLogin({ variables: { email, password } })
                .then((response) => {
                    if (response && response?.data) {
                        console.log(response, "login");
                        localStorage.setItem("accessToken", response?.data?.userLogin?.token)
                        localStorage.setItem("user", JSON.stringify(response?.data?.userLogin?.user))
                        toast.success("Login SuccessFully")
                        window.location.href= '/'
                    }
                });
            console.log(values.errors, "formErrors");

        },
    });
    console.log(data, "data")
    return (
        <>
            <div class="site-breadcrumb" style={{ background: "url(/assets/img/breadcrumb/01.jpg)" }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Login</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Login</li>
                    </ul>
                </div>
            </div>

            <div class="login-area py-120">
                <div class="container">
                    <div class="col-md-5 mx-auto">
                        <div class="login-form">
                            <div class="login-header">
                                <img src="/assets/img/logo/1.png" alt="" />
                                <p>Login with your Auto Motor account</p>
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <input type="email" class="form-control" placeholder="Your Email" name='email'
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />
                                </div>
                                {formik.errors.email && (
                                    <div className="error">{formik.errors.email}</div>
                                )}
                                <div class="form-group">
                                    <label>Password</label>
                                    <input type="password" class="form-control" placeholder="Your Password"
                                        name="password"
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                    />
                                </div>
                                {formik.errors.password && (
                                    <div className="error">{formik.errors.password}</div>
                                )}
                                <div class="d-flex justify-content-between mb-4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value id="remember" />
                                        <label class="form-check-label" for="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                    {/* <a href="forgot-password.html" class="forgot-pass">Forgot Password?</a> */}
                                </div>
                                <div class="d-flex align-items-center">
                                    <button type="submit" class="theme-btn"><i class="far fa-sign-in"></i> Login</button>
                                </div>
                            </form>
                            <div class="login-footer">
                                <p>Don't have an account? <Link to="/Register">Register.</Link></p>
                                {/* <div class="social-login">
                                    <p>Continue with social media</p>
                                    <div class="social-login-list">
                                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                                        <a href="#"><i class="fab fa-google"></i></a>
                                        <a href="#"><i class="fab fa-twitter"></i></a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default LoginForm