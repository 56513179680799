import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CorpartCard from '../Card/CorpartCard';

const CopartData = () => {
    return (
        <>
            <div class="row mt-5 d-lg-block d-none">
                <div class="col-lg-6 mx-auto">
                    <div class="site-heading text-center">
                        <span class="site-title-tagline"><i class="flaticon-drive"></i>Wholesale auctions: Choose from over 300k vehicle inventory</span>
                        <div class="heading-divider"></div>

                    </div>
                </div>
            </div>

            <div className='container mb-4'>
                <Tabs
                    defaultActiveKey="Makes"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Makes" title="Makes">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                        </div>

                    </Tab>
                    <Tab eventKey="Models" title="Models">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Featured" title="Featured">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Types" title="Types">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="Trending" title="Trending">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                            <div className='col-lg-3'>
                                <ul>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                    <li>Acura (3,341)</li>
                                    <li>Cadillac (3,599)</li>
                                    <li>Ford (39,319)</li>
                                </ul>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>

            <CorpartCard />
        </>
    )
}

export default CopartData