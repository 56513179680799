import {ApolloClient, InMemoryCache ,  HttpLink, ApolloLink } from '@apollo/client';

const httpLink = new HttpLink({ uri: "https://apiautomotor.devssh.xyz/graphql/" });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('accessToken'); 

  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;